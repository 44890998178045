// source: joblog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.joblog.AddJobLogEntriesArrayRequest', null, global);
goog.exportSymbol('proto.joblog.AddJobLogEntriesCommand', null, global);
goog.exportSymbol('proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand', null, global);
goog.exportSymbol('proto.joblog.AddJobLogEntriesCommand.CommandCase', null, global);
goog.exportSymbol('proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand', null, global);
goog.exportSymbol('proto.joblog.AddJobLogEntryRequest', null, global);
goog.exportSymbol('proto.joblog.CreateJobLogReply', null, global);
goog.exportSymbol('proto.joblog.CreateJobLogRequest', null, global);
goog.exportSymbol('proto.joblog.FinishJobLogRequest', null, global);
goog.exportSymbol('proto.joblog.GetJobLogEntriesArrayRequest', null, global);
goog.exportSymbol('proto.joblog.GetJobLogEntriesRequest', null, global);
goog.exportSymbol('proto.joblog.GetJobLogRequest', null, global);
goog.exportSymbol('proto.joblog.GetJobLogViewerURLReply', null, global);
goog.exportSymbol('proto.joblog.GetJobLogViewerURLRequest', null, global);
goog.exportSymbol('proto.joblog.JobLog', null, global);
goog.exportSymbol('proto.joblog.JobLogEntriesArray', null, global);
goog.exportSymbol('proto.joblog.JobLogEntry', null, global);
goog.exportSymbol('proto.joblog.JobLogState', null, global);
goog.exportSymbol('proto.joblog.JobLogStreamEntry', null, global);
goog.exportSymbol('proto.joblog.JobLogStreamEntry.MessageCase', null, global);
goog.exportSymbol('proto.joblog.Level', null, global);
goog.exportSymbol('proto.joblog.UpgradeViewerTokenReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.GetJobLogEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.GetJobLogEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.GetJobLogEntriesRequest.displayName = 'proto.joblog.GetJobLogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.GetJobLogEntriesArrayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.GetJobLogEntriesArrayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.GetJobLogEntriesArrayRequest.displayName = 'proto.joblog.GetJobLogEntriesArrayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.AddJobLogEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.AddJobLogEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.AddJobLogEntryRequest.displayName = 'proto.joblog.AddJobLogEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.AddJobLogEntriesArrayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.joblog.AddJobLogEntriesArrayRequest.repeatedFields_, null);
};
goog.inherits(proto.joblog.AddJobLogEntriesArrayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.AddJobLogEntriesArrayRequest.displayName = 'proto.joblog.AddJobLogEntriesArrayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.UpgradeViewerTokenReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.UpgradeViewerTokenReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.UpgradeViewerTokenReply.displayName = 'proto.joblog.UpgradeViewerTokenReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.CreateJobLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.CreateJobLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.CreateJobLogRequest.displayName = 'proto.joblog.CreateJobLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.GetJobLogViewerURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.GetJobLogViewerURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.GetJobLogViewerURLRequest.displayName = 'proto.joblog.GetJobLogViewerURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.GetJobLogViewerURLReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.GetJobLogViewerURLReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.GetJobLogViewerURLReply.displayName = 'proto.joblog.GetJobLogViewerURLReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.FinishJobLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.FinishJobLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.FinishJobLogRequest.displayName = 'proto.joblog.FinishJobLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.CreateJobLogReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.CreateJobLogReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.CreateJobLogReply.displayName = 'proto.joblog.CreateJobLogReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.GetJobLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.GetJobLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.GetJobLogRequest.displayName = 'proto.joblog.GetJobLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.JobLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.JobLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.JobLog.displayName = 'proto.joblog.JobLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.JobLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.JobLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.JobLogEntry.displayName = 'proto.joblog.JobLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.JobLogEntriesArray = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.joblog.JobLogEntriesArray.repeatedFields_, null);
};
goog.inherits(proto.joblog.JobLogEntriesArray, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.JobLogEntriesArray.displayName = 'proto.joblog.JobLogEntriesArray';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.JobLogStreamEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.joblog.JobLogStreamEntry.oneofGroups_);
};
goog.inherits(proto.joblog.JobLogStreamEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.JobLogStreamEntry.displayName = 'proto.joblog.JobLogStreamEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.AddJobLogEntriesCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.joblog.AddJobLogEntriesCommand.oneofGroups_);
};
goog.inherits(proto.joblog.AddJobLogEntriesCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.AddJobLogEntriesCommand.displayName = 'proto.joblog.AddJobLogEntriesCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.displayName = 'proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.displayName = 'proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.GetJobLogEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.GetJobLogEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    keepopen: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    afterid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.GetJobLogEntriesRequest}
 */
proto.joblog.GetJobLogEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.GetJobLogEntriesRequest;
  return proto.joblog.GetJobLogEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.GetJobLogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.GetJobLogEntriesRequest}
 */
proto.joblog.GetJobLogEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeepopen(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAfterid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.GetJobLogEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.GetJobLogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getKeepopen();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAfterid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
 */
proto.joblog.GetJobLogEntriesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
*/
proto.joblog.GetJobLogEntriesRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
 */
proto.joblog.GetJobLogEntriesRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
*/
proto.joblog.GetJobLogEntriesRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
 */
proto.joblog.GetJobLogEntriesRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool keepOpen = 4;
 * @return {boolean}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.getKeepopen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
 */
proto.joblog.GetJobLogEntriesRequest.prototype.setKeepopen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string afterId = 5;
 * @return {string}
 */
proto.joblog.GetJobLogEntriesRequest.prototype.getAfterid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogEntriesRequest} returns this
 */
proto.joblog.GetJobLogEntriesRequest.prototype.setAfterid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.GetJobLogEntriesArrayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.GetJobLogEntriesArrayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogEntriesArrayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest}
 */
proto.joblog.GetJobLogEntriesArrayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.GetJobLogEntriesArrayRequest;
  return proto.joblog.GetJobLogEntriesArrayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.GetJobLogEntriesArrayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest}
 */
proto.joblog.GetJobLogEntriesArrayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.GetJobLogEntriesArrayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.GetJobLogEntriesArrayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogEntriesArrayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest} returns this
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest} returns this
*/
proto.joblog.GetJobLogEntriesArrayRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest} returns this
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest} returns this
*/
proto.joblog.GetJobLogEntriesArrayRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.GetJobLogEntriesArrayRequest} returns this
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.GetJobLogEntriesArrayRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.AddJobLogEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.AddJobLogEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.AddJobLogEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0),
    joblogid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clienttimestamp: (f = msg.getClienttimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.AddJobLogEntryRequest}
 */
proto.joblog.AddJobLogEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.AddJobLogEntryRequest;
  return proto.joblog.AddJobLogEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.AddJobLogEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.AddJobLogEntryRequest}
 */
proto.joblog.AddJobLogEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.joblog.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setJoblogid(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setClienttimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.AddJobLogEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.AddJobLogEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.AddJobLogEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getJoblogid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClienttimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.joblog.AddJobLogEntryRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.AddJobLogEntryRequest} returns this
 */
proto.joblog.AddJobLogEntryRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Level level = 2;
 * @return {!proto.joblog.Level}
 */
proto.joblog.AddJobLogEntryRequest.prototype.getLevel = function() {
  return /** @type {!proto.joblog.Level} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.joblog.Level} value
 * @return {!proto.joblog.AddJobLogEntryRequest} returns this
 */
proto.joblog.AddJobLogEntryRequest.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string jobLogId = 3;
 * @return {string}
 */
proto.joblog.AddJobLogEntryRequest.prototype.getJoblogid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.AddJobLogEntryRequest} returns this
 */
proto.joblog.AddJobLogEntryRequest.prototype.setJoblogid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp clientTimestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.AddJobLogEntryRequest.prototype.getClienttimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.AddJobLogEntryRequest} returns this
*/
proto.joblog.AddJobLogEntryRequest.prototype.setClienttimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.AddJobLogEntryRequest} returns this
 */
proto.joblog.AddJobLogEntryRequest.prototype.clearClienttimestamp = function() {
  return this.setClienttimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.AddJobLogEntryRequest.prototype.hasClienttimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.joblog.AddJobLogEntriesArrayRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.AddJobLogEntriesArrayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.AddJobLogEntriesArrayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.AddJobLogEntriesArrayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesArrayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.joblog.AddJobLogEntryRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.AddJobLogEntriesArrayRequest}
 */
proto.joblog.AddJobLogEntriesArrayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.AddJobLogEntriesArrayRequest;
  return proto.joblog.AddJobLogEntriesArrayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.AddJobLogEntriesArrayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.AddJobLogEntriesArrayRequest}
 */
proto.joblog.AddJobLogEntriesArrayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.joblog.AddJobLogEntryRequest;
      reader.readMessage(value,proto.joblog.AddJobLogEntryRequest.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.AddJobLogEntriesArrayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.AddJobLogEntriesArrayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.AddJobLogEntriesArrayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesArrayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.joblog.AddJobLogEntryRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AddJobLogEntryRequest entries = 1;
 * @return {!Array<!proto.joblog.AddJobLogEntryRequest>}
 */
proto.joblog.AddJobLogEntriesArrayRequest.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.joblog.AddJobLogEntryRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.joblog.AddJobLogEntryRequest, 1));
};


/**
 * @param {!Array<!proto.joblog.AddJobLogEntryRequest>} value
 * @return {!proto.joblog.AddJobLogEntriesArrayRequest} returns this
*/
proto.joblog.AddJobLogEntriesArrayRequest.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.joblog.AddJobLogEntryRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.joblog.AddJobLogEntryRequest}
 */
proto.joblog.AddJobLogEntriesArrayRequest.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.joblog.AddJobLogEntryRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.joblog.AddJobLogEntriesArrayRequest} returns this
 */
proto.joblog.AddJobLogEntriesArrayRequest.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.UpgradeViewerTokenReply.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.UpgradeViewerTokenReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.UpgradeViewerTokenReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.UpgradeViewerTokenReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    longlivedtoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.UpgradeViewerTokenReply}
 */
proto.joblog.UpgradeViewerTokenReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.UpgradeViewerTokenReply;
  return proto.joblog.UpgradeViewerTokenReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.UpgradeViewerTokenReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.UpgradeViewerTokenReply}
 */
proto.joblog.UpgradeViewerTokenReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLonglivedtoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.UpgradeViewerTokenReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.UpgradeViewerTokenReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.UpgradeViewerTokenReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.UpgradeViewerTokenReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLonglivedtoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string longLivedToken = 1;
 * @return {string}
 */
proto.joblog.UpgradeViewerTokenReply.prototype.getLonglivedtoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.UpgradeViewerTokenReply} returns this
 */
proto.joblog.UpgradeViewerTokenReply.prototype.setLonglivedtoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.CreateJobLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.CreateJobLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.CreateJobLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.CreateJobLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clienttimestamp: (f = msg.getClienttimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    initialMessage: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.CreateJobLogRequest}
 */
proto.joblog.CreateJobLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.CreateJobLogRequest;
  return proto.joblog.CreateJobLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.CreateJobLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.CreateJobLogRequest}
 */
proto.joblog.CreateJobLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setClienttimestamp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.CreateJobLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.CreateJobLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.CreateJobLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.CreateJobLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClienttimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getInitialMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.joblog.CreateJobLogRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.CreateJobLogRequest} returns this
 */
proto.joblog.CreateJobLogRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.joblog.CreateJobLogRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.CreateJobLogRequest} returns this
 */
proto.joblog.CreateJobLogRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string producer = 3;
 * @return {string}
 */
proto.joblog.CreateJobLogRequest.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.CreateJobLogRequest} returns this
 */
proto.joblog.CreateJobLogRequest.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp clientTimestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.CreateJobLogRequest.prototype.getClienttimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.CreateJobLogRequest} returns this
*/
proto.joblog.CreateJobLogRequest.prototype.setClienttimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.CreateJobLogRequest} returns this
 */
proto.joblog.CreateJobLogRequest.prototype.clearClienttimestamp = function() {
  return this.setClienttimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.CreateJobLogRequest.prototype.hasClienttimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string initial_message = 5;
 * @return {string}
 */
proto.joblog.CreateJobLogRequest.prototype.getInitialMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.CreateJobLogRequest} returns this
 */
proto.joblog.CreateJobLogRequest.prototype.setInitialMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.GetJobLogViewerURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.GetJobLogViewerURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.GetJobLogViewerURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogViewerURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.GetJobLogViewerURLRequest}
 */
proto.joblog.GetJobLogViewerURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.GetJobLogViewerURLRequest;
  return proto.joblog.GetJobLogViewerURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.GetJobLogViewerURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.GetJobLogViewerURLRequest}
 */
proto.joblog.GetJobLogViewerURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.GetJobLogViewerURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.GetJobLogViewerURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.GetJobLogViewerURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogViewerURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.GetJobLogViewerURLRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogViewerURLRequest} returns this
 */
proto.joblog.GetJobLogViewerURLRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.GetJobLogViewerURLReply.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.GetJobLogViewerURLReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.GetJobLogViewerURLReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogViewerURLReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.GetJobLogViewerURLReply}
 */
proto.joblog.GetJobLogViewerURLReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.GetJobLogViewerURLReply;
  return proto.joblog.GetJobLogViewerURLReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.GetJobLogViewerURLReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.GetJobLogViewerURLReply}
 */
proto.joblog.GetJobLogViewerURLReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.GetJobLogViewerURLReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.GetJobLogViewerURLReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.GetJobLogViewerURLReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogViewerURLReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.joblog.GetJobLogViewerURLReply.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogViewerURLReply} returns this
 */
proto.joblog.GetJobLogViewerURLReply.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.FinishJobLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.FinishJobLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.FinishJobLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.FinishJobLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.FinishJobLogRequest}
 */
proto.joblog.FinishJobLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.FinishJobLogRequest;
  return proto.joblog.FinishJobLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.FinishJobLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.FinishJobLogRequest}
 */
proto.joblog.FinishJobLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.FinishJobLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.FinishJobLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.FinishJobLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.FinishJobLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.FinishJobLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.FinishJobLogRequest} returns this
 */
proto.joblog.FinishJobLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.CreateJobLogReply.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.CreateJobLogReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.CreateJobLogReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.CreateJobLogReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.CreateJobLogReply}
 */
proto.joblog.CreateJobLogReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.CreateJobLogReply;
  return proto.joblog.CreateJobLogReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.CreateJobLogReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.CreateJobLogReply}
 */
proto.joblog.CreateJobLogReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.CreateJobLogReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.CreateJobLogReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.CreateJobLogReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.CreateJobLogReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.CreateJobLogReply.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.CreateJobLogReply} returns this
 */
proto.joblog.CreateJobLogReply.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.GetJobLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.GetJobLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.GetJobLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.GetJobLogRequest}
 */
proto.joblog.GetJobLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.GetJobLogRequest;
  return proto.joblog.GetJobLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.GetJobLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.GetJobLogRequest}
 */
proto.joblog.GetJobLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.GetJobLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.GetJobLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.GetJobLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.GetJobLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.GetJobLogRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.GetJobLogRequest} returns this
 */
proto.joblog.GetJobLogRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.JobLog.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.JobLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.JobLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    producer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.JobLog}
 */
proto.joblog.JobLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.JobLog;
  return proto.joblog.JobLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.JobLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.JobLog}
 */
proto.joblog.JobLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProducer(value);
      break;
    case 5:
      var value = /** @type {!proto.joblog.JobLogState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.JobLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.JobLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.JobLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProducer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.JobLog.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.joblog.JobLog.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.joblog.JobLog.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string producer = 4;
 * @return {string}
 */
proto.joblog.JobLog.prototype.getProducer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.setProducer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional JobLogState state = 5;
 * @return {!proto.joblog.JobLogState}
 */
proto.joblog.JobLog.prototype.getState = function() {
  return /** @type {!proto.joblog.JobLogState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.joblog.JobLogState} value
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp created = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.JobLog.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.JobLog} returns this
*/
proto.joblog.JobLog.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.JobLog} returns this
 */
proto.joblog.JobLog.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.JobLog.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.JobLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.JobLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.JobLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    level: jspb.Message.getFieldWithDefault(msg, 2, 0),
    clienttimestamp: (f = msg.getClienttimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.JobLogEntry}
 */
proto.joblog.JobLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.JobLogEntry;
  return proto.joblog.JobLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.JobLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.JobLogEntry}
 */
proto.joblog.JobLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.joblog.Level} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setClienttimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.JobLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.JobLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.JobLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClienttimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.joblog.JobLogEntry.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLogEntry} returns this
 */
proto.joblog.JobLogEntry.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Level level = 2;
 * @return {!proto.joblog.Level}
 */
proto.joblog.JobLogEntry.prototype.getLevel = function() {
  return /** @type {!proto.joblog.Level} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.joblog.Level} value
 * @return {!proto.joblog.JobLogEntry} returns this
 */
proto.joblog.JobLogEntry.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp clientTimestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.joblog.JobLogEntry.prototype.getClienttimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.joblog.JobLogEntry} returns this
*/
proto.joblog.JobLogEntry.prototype.setClienttimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.JobLogEntry} returns this
 */
proto.joblog.JobLogEntry.prototype.clearClienttimestamp = function() {
  return this.setClienttimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.JobLogEntry.prototype.hasClienttimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.joblog.JobLogEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.JobLogEntry} returns this
 */
proto.joblog.JobLogEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.joblog.JobLogEntriesArray.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.JobLogEntriesArray.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.JobLogEntriesArray.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.JobLogEntriesArray} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogEntriesArray.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.joblog.JobLogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.JobLogEntriesArray}
 */
proto.joblog.JobLogEntriesArray.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.JobLogEntriesArray;
  return proto.joblog.JobLogEntriesArray.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.JobLogEntriesArray} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.JobLogEntriesArray}
 */
proto.joblog.JobLogEntriesArray.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.joblog.JobLogEntry;
      reader.readMessage(value,proto.joblog.JobLogEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.JobLogEntriesArray.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.JobLogEntriesArray.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.JobLogEntriesArray} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogEntriesArray.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.joblog.JobLogEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated JobLogEntry entries = 1;
 * @return {!Array<!proto.joblog.JobLogEntry>}
 */
proto.joblog.JobLogEntriesArray.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.joblog.JobLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.joblog.JobLogEntry, 1));
};


/**
 * @param {!Array<!proto.joblog.JobLogEntry>} value
 * @return {!proto.joblog.JobLogEntriesArray} returns this
*/
proto.joblog.JobLogEntriesArray.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.joblog.JobLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.joblog.JobLogEntry}
 */
proto.joblog.JobLogEntriesArray.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.joblog.JobLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.joblog.JobLogEntriesArray} returns this
 */
proto.joblog.JobLogEntriesArray.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.joblog.JobLogStreamEntry.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.joblog.JobLogStreamEntry.MessageCase = {
  MESSAGE_NOT_SET: 0,
  ENTRY: 1,
  PING: 2
};

/**
 * @return {proto.joblog.JobLogStreamEntry.MessageCase}
 */
proto.joblog.JobLogStreamEntry.prototype.getMessageCase = function() {
  return /** @type {proto.joblog.JobLogStreamEntry.MessageCase} */(jspb.Message.computeOneofCase(this, proto.joblog.JobLogStreamEntry.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.JobLogStreamEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.JobLogStreamEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.JobLogStreamEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogStreamEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    entry: (f = msg.getEntry()) && proto.joblog.JobLogEntry.toObject(includeInstance, f),
    ping: (f = msg.getPing()) && google_protobuf_empty_pb.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.JobLogStreamEntry}
 */
proto.joblog.JobLogStreamEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.JobLogStreamEntry;
  return proto.joblog.JobLogStreamEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.JobLogStreamEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.JobLogStreamEntry}
 */
proto.joblog.JobLogStreamEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.joblog.JobLogEntry;
      reader.readMessage(value,proto.joblog.JobLogEntry.deserializeBinaryFromReader);
      msg.setEntry(value);
      break;
    case 2:
      var value = new google_protobuf_empty_pb.Empty;
      reader.readMessage(value,google_protobuf_empty_pb.Empty.deserializeBinaryFromReader);
      msg.setPing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.JobLogStreamEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.JobLogStreamEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.JobLogStreamEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.JobLogStreamEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntry();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.joblog.JobLogEntry.serializeBinaryToWriter
    );
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_empty_pb.Empty.serializeBinaryToWriter
    );
  }
};


/**
 * optional JobLogEntry entry = 1;
 * @return {?proto.joblog.JobLogEntry}
 */
proto.joblog.JobLogStreamEntry.prototype.getEntry = function() {
  return /** @type{?proto.joblog.JobLogEntry} */ (
    jspb.Message.getWrapperField(this, proto.joblog.JobLogEntry, 1));
};


/**
 * @param {?proto.joblog.JobLogEntry|undefined} value
 * @return {!proto.joblog.JobLogStreamEntry} returns this
*/
proto.joblog.JobLogStreamEntry.prototype.setEntry = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.joblog.JobLogStreamEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.JobLogStreamEntry} returns this
 */
proto.joblog.JobLogStreamEntry.prototype.clearEntry = function() {
  return this.setEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.JobLogStreamEntry.prototype.hasEntry = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Empty ping = 2;
 * @return {?proto.google.protobuf.Empty}
 */
proto.joblog.JobLogStreamEntry.prototype.getPing = function() {
  return /** @type{?proto.google.protobuf.Empty} */ (
    jspb.Message.getWrapperField(this, google_protobuf_empty_pb.Empty, 2));
};


/**
 * @param {?proto.google.protobuf.Empty|undefined} value
 * @return {!proto.joblog.JobLogStreamEntry} returns this
*/
proto.joblog.JobLogStreamEntry.prototype.setPing = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.joblog.JobLogStreamEntry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.JobLogStreamEntry} returns this
 */
proto.joblog.JobLogStreamEntry.prototype.clearPing = function() {
  return this.setPing(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.JobLogStreamEntry.prototype.hasPing = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.joblog.AddJobLogEntriesCommand.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.joblog.AddJobLogEntriesCommand.CommandCase = {
  COMMAND_NOT_SET: 0,
  SETJOBLOG: 1,
  APPENDENTRY: 2
};

/**
 * @return {proto.joblog.AddJobLogEntriesCommand.CommandCase}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.getCommandCase = function() {
  return /** @type {proto.joblog.AddJobLogEntriesCommand.CommandCase} */(jspb.Message.computeOneofCase(this, proto.joblog.AddJobLogEntriesCommand.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.AddJobLogEntriesCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.AddJobLogEntriesCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    setjoblog: (f = msg.getSetjoblog()) && proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.toObject(includeInstance, f),
    appendentry: (f = msg.getAppendentry()) && proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.AddJobLogEntriesCommand}
 */
proto.joblog.AddJobLogEntriesCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.AddJobLogEntriesCommand;
  return proto.joblog.AddJobLogEntriesCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.AddJobLogEntriesCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.AddJobLogEntriesCommand}
 */
proto.joblog.AddJobLogEntriesCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand;
      reader.readMessage(value,proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.deserializeBinaryFromReader);
      msg.setSetjoblog(value);
      break;
    case 2:
      var value = new proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand;
      reader.readMessage(value,proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.deserializeBinaryFromReader);
      msg.setAppendentry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.AddJobLogEntriesCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.AddJobLogEntriesCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetjoblog();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.serializeBinaryToWriter
    );
  }
  f = message.getAppendentry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    entry: (f = msg.getEntry()) && proto.joblog.AddJobLogEntryRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand;
  return proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.joblog.AddJobLogEntryRequest;
      reader.readMessage(value,proto.joblog.AddJobLogEntryRequest.deserializeBinaryFromReader);
      msg.setEntry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntry();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.joblog.AddJobLogEntryRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddJobLogEntryRequest entry = 1;
 * @return {?proto.joblog.AddJobLogEntryRequest}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.getEntry = function() {
  return /** @type{?proto.joblog.AddJobLogEntryRequest} */ (
    jspb.Message.getWrapperField(this, proto.joblog.AddJobLogEntryRequest, 1));
};


/**
 * @param {?proto.joblog.AddJobLogEntryRequest|undefined} value
 * @return {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} returns this
*/
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.setEntry = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} returns this
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.clearEntry = function() {
  return this.setEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand.prototype.hasEntry = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand}
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand;
  return proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand}
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand} returns this
 */
proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SetJobLogCommand setJobLog = 1;
 * @return {?proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.getSetjoblog = function() {
  return /** @type{?proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand} */ (
    jspb.Message.getWrapperField(this, proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand, 1));
};


/**
 * @param {?proto.joblog.AddJobLogEntriesCommand.SetJobLogCommand|undefined} value
 * @return {!proto.joblog.AddJobLogEntriesCommand} returns this
*/
proto.joblog.AddJobLogEntriesCommand.prototype.setSetjoblog = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.joblog.AddJobLogEntriesCommand.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.AddJobLogEntriesCommand} returns this
 */
proto.joblog.AddJobLogEntriesCommand.prototype.clearSetjoblog = function() {
  return this.setSetjoblog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.hasSetjoblog = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AppendEntryCommand appendEntry = 2;
 * @return {?proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.getAppendentry = function() {
  return /** @type{?proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand} */ (
    jspb.Message.getWrapperField(this, proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand, 2));
};


/**
 * @param {?proto.joblog.AddJobLogEntriesCommand.AppendEntryCommand|undefined} value
 * @return {!proto.joblog.AddJobLogEntriesCommand} returns this
*/
proto.joblog.AddJobLogEntriesCommand.prototype.setAppendentry = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.joblog.AddJobLogEntriesCommand.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.joblog.AddJobLogEntriesCommand} returns this
 */
proto.joblog.AddJobLogEntriesCommand.prototype.clearAppendentry = function() {
  return this.setAppendentry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.joblog.AddJobLogEntriesCommand.prototype.hasAppendentry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.joblog.JobLogState = {
  CREATED: 0,
  FINISHED: 1
};

/**
 * @enum {number}
 */
proto.joblog.Level = {
  TRACE: 0,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  FATAL: 5
};

goog.object.extend(exports, proto.joblog);
