import { createApp } from "vue";
import { router } from "./router";



import App from "./App.vue";

const token = localStorage.getItem("");

createApp(App).use(router).mount("#app");
