/**
 * @fileoverview gRPC-Web generated client stub for joblog
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';
import * as joblog_pb from './joblog_pb';


export class JobLogViewerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetJobLog = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogViewer/GetJobLog',
    grpcWeb.MethodType.UNARY,
    joblog_pb.GetJobLogRequest,
    joblog_pb.JobLog,
    (request: joblog_pb.GetJobLogRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLog.deserializeBinary
  );

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.JobLog>;

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLog) => void): grpcWeb.ClientReadableStream<joblog_pb.JobLog>;

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLog) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogViewer/GetJobLog',
        request,
        metadata || {},
        this.methodDescriptorGetJobLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogViewer/GetJobLog',
    request,
    metadata || {},
    this.methodDescriptorGetJobLog);
  }

  methodDescriptorGetLogEntries = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogViewer/GetLogEntries',
    grpcWeb.MethodType.SERVER_STREAMING,
    joblog_pb.GetJobLogEntriesRequest,
    joblog_pb.JobLogStreamEntry,
    (request: joblog_pb.GetJobLogEntriesRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLogStreamEntry.deserializeBinary
  );

  getLogEntries(
    request: joblog_pb.GetJobLogEntriesRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<joblog_pb.JobLogStreamEntry> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/joblog.JobLogViewer/GetLogEntries',
      request,
      metadata || {},
      this.methodDescriptorGetLogEntries);
  }

  methodDescriptorUpgradeViewerToken = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogViewer/UpgradeViewerToken',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    joblog_pb.UpgradeViewerTokenReply,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    joblog_pb.UpgradeViewerTokenReply.deserializeBinary
  );

  upgradeViewerToken(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.UpgradeViewerTokenReply>;

  upgradeViewerToken(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.UpgradeViewerTokenReply) => void): grpcWeb.ClientReadableStream<joblog_pb.UpgradeViewerTokenReply>;

  upgradeViewerToken(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.UpgradeViewerTokenReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogViewer/UpgradeViewerToken',
        request,
        metadata || {},
        this.methodDescriptorUpgradeViewerToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogViewer/UpgradeViewerToken',
    request,
    metadata || {},
    this.methodDescriptorUpgradeViewerToken);
  }

  methodDescriptorGetLogEntriesArray = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogViewer/GetLogEntriesArray',
    grpcWeb.MethodType.UNARY,
    joblog_pb.GetJobLogEntriesArrayRequest,
    joblog_pb.JobLogEntriesArray,
    (request: joblog_pb.GetJobLogEntriesArrayRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLogEntriesArray.deserializeBinary
  );

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.JobLogEntriesArray>;

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLogEntriesArray) => void): grpcWeb.ClientReadableStream<joblog_pb.JobLogEntriesArray>;

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLogEntriesArray) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogViewer/GetLogEntriesArray',
        request,
        metadata || {},
        this.methodDescriptorGetLogEntriesArray,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogViewer/GetLogEntriesArray',
    request,
    metadata || {},
    this.methodDescriptorGetLogEntriesArray);
  }

}

export class JobLoggerClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateJobLog = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/CreateJobLog',
    grpcWeb.MethodType.UNARY,
    joblog_pb.CreateJobLogRequest,
    joblog_pb.CreateJobLogReply,
    (request: joblog_pb.CreateJobLogRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.CreateJobLogReply.deserializeBinary
  );

  createJobLog(
    request: joblog_pb.CreateJobLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.CreateJobLogReply>;

  createJobLog(
    request: joblog_pb.CreateJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.CreateJobLogReply) => void): grpcWeb.ClientReadableStream<joblog_pb.CreateJobLogReply>;

  createJobLog(
    request: joblog_pb.CreateJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.CreateJobLogReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/CreateJobLog',
        request,
        metadata || {},
        this.methodDescriptorCreateJobLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/CreateJobLog',
    request,
    metadata || {},
    this.methodDescriptorCreateJobLog);
  }

  methodDescriptorFinishJobLog = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/FinishJobLog',
    grpcWeb.MethodType.UNARY,
    joblog_pb.FinishJobLogRequest,
    google_protobuf_empty_pb.Empty,
    (request: joblog_pb.FinishJobLogRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  finishJobLog(
    request: joblog_pb.FinishJobLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  finishJobLog(
    request: joblog_pb.FinishJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  finishJobLog(
    request: joblog_pb.FinishJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/FinishJobLog',
        request,
        metadata || {},
        this.methodDescriptorFinishJobLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/FinishJobLog',
    request,
    metadata || {},
    this.methodDescriptorFinishJobLog);
  }

  methodDescriptorGetJobLog = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/GetJobLog',
    grpcWeb.MethodType.UNARY,
    joblog_pb.GetJobLogRequest,
    joblog_pb.JobLog,
    (request: joblog_pb.GetJobLogRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLog.deserializeBinary
  );

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.JobLog>;

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLog) => void): grpcWeb.ClientReadableStream<joblog_pb.JobLog>;

  getJobLog(
    request: joblog_pb.GetJobLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLog) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/GetJobLog',
        request,
        metadata || {},
        this.methodDescriptorGetJobLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/GetJobLog',
    request,
    metadata || {},
    this.methodDescriptorGetJobLog);
  }

  methodDescriptorAddLogEntry = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/AddLogEntry',
    grpcWeb.MethodType.UNARY,
    joblog_pb.AddJobLogEntryRequest,
    google_protobuf_empty_pb.Empty,
    (request: joblog_pb.AddJobLogEntryRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addLogEntry(
    request: joblog_pb.AddJobLogEntryRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addLogEntry(
    request: joblog_pb.AddJobLogEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addLogEntry(
    request: joblog_pb.AddJobLogEntryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/AddLogEntry',
        request,
        metadata || {},
        this.methodDescriptorAddLogEntry,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/AddLogEntry',
    request,
    metadata || {},
    this.methodDescriptorAddLogEntry);
  }

  methodDescriptorAddLogEntriesArray = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/AddLogEntriesArray',
    grpcWeb.MethodType.UNARY,
    joblog_pb.AddJobLogEntriesArrayRequest,
    google_protobuf_empty_pb.Empty,
    (request: joblog_pb.AddJobLogEntriesArrayRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addLogEntriesArray(
    request: joblog_pb.AddJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addLogEntriesArray(
    request: joblog_pb.AddJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addLogEntriesArray(
    request: joblog_pb.AddJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/AddLogEntriesArray',
        request,
        metadata || {},
        this.methodDescriptorAddLogEntriesArray,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/AddLogEntriesArray',
    request,
    metadata || {},
    this.methodDescriptorAddLogEntriesArray);
  }

  methodDescriptorGetLogEntries = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/GetLogEntries',
    grpcWeb.MethodType.SERVER_STREAMING,
    joblog_pb.GetJobLogEntriesRequest,
    joblog_pb.JobLogStreamEntry,
    (request: joblog_pb.GetJobLogEntriesRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLogStreamEntry.deserializeBinary
  );

  getLogEntries(
    request: joblog_pb.GetJobLogEntriesRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<joblog_pb.JobLogStreamEntry> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/joblog.JobLogger/GetLogEntries',
      request,
      metadata || {},
      this.methodDescriptorGetLogEntries);
  }

  methodDescriptorGetLogEntriesArray = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/GetLogEntriesArray',
    grpcWeb.MethodType.UNARY,
    joblog_pb.GetJobLogEntriesArrayRequest,
    joblog_pb.JobLogEntriesArray,
    (request: joblog_pb.GetJobLogEntriesArrayRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.JobLogEntriesArray.deserializeBinary
  );

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.JobLogEntriesArray>;

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLogEntriesArray) => void): grpcWeb.ClientReadableStream<joblog_pb.JobLogEntriesArray>;

  getLogEntriesArray(
    request: joblog_pb.GetJobLogEntriesArrayRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.JobLogEntriesArray) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/GetLogEntriesArray',
        request,
        metadata || {},
        this.methodDescriptorGetLogEntriesArray,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/GetLogEntriesArray',
    request,
    metadata || {},
    this.methodDescriptorGetLogEntriesArray);
  }

  methodDescriptorGetJobLogViewerURL = new grpcWeb.MethodDescriptor(
    '/joblog.JobLogger/GetJobLogViewerURL',
    grpcWeb.MethodType.UNARY,
    joblog_pb.GetJobLogViewerURLRequest,
    joblog_pb.GetJobLogViewerURLReply,
    (request: joblog_pb.GetJobLogViewerURLRequest) => {
      return request.serializeBinary();
    },
    joblog_pb.GetJobLogViewerURLReply.deserializeBinary
  );

  getJobLogViewerURL(
    request: joblog_pb.GetJobLogViewerURLRequest,
    metadata: grpcWeb.Metadata | null): Promise<joblog_pb.GetJobLogViewerURLReply>;

  getJobLogViewerURL(
    request: joblog_pb.GetJobLogViewerURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: joblog_pb.GetJobLogViewerURLReply) => void): grpcWeb.ClientReadableStream<joblog_pb.GetJobLogViewerURLReply>;

  getJobLogViewerURL(
    request: joblog_pb.GetJobLogViewerURLRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: joblog_pb.GetJobLogViewerURLReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/joblog.JobLogger/GetJobLogViewerURL',
        request,
        metadata || {},
        this.methodDescriptorGetJobLogViewerURL,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/joblog.JobLogger/GetJobLogViewerURL',
    request,
    metadata || {},
    this.methodDescriptorGetJobLogViewerURL);
  }

}

